import React from 'react'
import { Link } from 'react-router-dom'
import Ctaimg from '../../assets/images/resources/about-mid-section.png'

function Ctathree() {
    return (
        <>
            <section className="cta-three">
                <div className="cta-three__bg jarallax" data-jarallax="" data-speed="0.3" style={{ backgroundImage: `url(${Ctaimg})` }} />
                <div className="container">
                    <h2 className="cta-three__title">
                        Trusted Employer of Record &amp;<br />
                        Recruitment Agency
                    </h2>
                    <div className="cta-three__btns">
                        <Link to="/executive-search" className="hiredots-btn cta-three__link">
                            <span>Discover more</span>
                        </Link>
                        <Link to="/employer-of-record" className="hiredots-btn hiredots-btn--primary">
                            <span>employer of record</span>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ctathree